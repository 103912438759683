import React from 'react';
import { graphql } from 'gatsby';
import ReactMapboxGl, { Marker } from 'react-mapbox-gl';
import styled from '@emotion/styled';
import TouchCarousel from 'react-touch-carousel';
import { MDXRenderer } from 'gatsby-plugin-mdx';

import Container from 'src/components/Container';
import PageHeading from 'src/components/PageHeading';
import LocationList from 'src/components/LocationList';
import PlaceCard from 'src/components/PlaceCard';
import { useIsMobile } from 'src/utils/useIsMobile';

const FOCUS_ZOOM = 17;
const DEFAULT_MAP_STYLE = 'mapbox://styles/macopon/ck5um42j31syx1ipjdjgl1thi';
const MAP_TOKEN =
  'pk.eyJ1IjoibWFjb3BvbiIsImEiOiJjazV1bTI3bXoxYXdnM2tuMTk4dmZzNXN4In0.gGGEV68tw1C12CkfZgp2nQ';

const mapboxConfig = {
  attributionControl: false,
  scrollZoom: false,
  interactive: false,
  accessToken: MAP_TOKEN,
  logoPosition: 'top-left',
};

const locationListAnimations = {
  visible: {
    transition: { delay: 0.5 },
    opacity: 1,
    y: 0,
  },
  hidden: {
    transition: { delay: 1 },
    opacity: 0,
    y: 100,
  },
  hovered: {
    scale: 1.05,
    opacity: 1,
  },
};

// XXX: Cannot render mapbox in SSR mode, so we just render some empty divs instead
const { Map, MapMarker } =
  typeof window !== 'undefined'
    ? { Map: ReactMapboxGl(mapboxConfig), MapMarker: Marker }
    : { Map: p => <div className={p.className} />, MapMarker: p => <div /> };

const getBoundOfLocations = locationList =>
  locationList.reduce(
    ([[tlLong, tlLat], [brLong, brLat]], [long, lat]) => [
      [Math.min(tlLong, long), Math.max(tlLat, lat)],
      [Math.max(brLong, long), Math.min(brLat, lat)],
    ],
    [[180, -180], [-180, 180]],
  );

const padBounds = (padding, [[tlLong, tlLat], [brLong, brLat]]) => [
  [tlLong - padding, tlLat + padding * 1],
  [brLong + padding, brLat - padding * 5],
];

const Template = ({ data }) => {
  const isMobile = useIsMobile();

  const [focusedLoc, setFocusedLoc] = React.useState(null);
  const [focusedGroup, setFocusedGroup] = React.useState(0);

  const introBody = data.mdx.body;
  const { title, mapStyle, mapEntries } = data.mdx.frontmatter;

  // Ensure the map is rendered in the browser outside of SSR
  const [, updateState] = React.useState('server');
  React.useEffect(() => updateState('client'), []);

  React.useEffect(() => {
    window.document.body.style.overflow =
      focusedLoc === null ? 'auto' : 'hidden';
  });

  if (mapEntries === null) {
    return (
      <Container>
        <PageHeading>{title}</PageHeading>
        <Intro>{introBody}</Intro>
      </Container>
    );
  }

  const getGroup = index => ({
    ...mapEntries[index],
    locations: mapEntries[index].locations.map(l => ({
      ...l.place.childMdx?.frontmatter,
      body: l.place.childMdx?.body,
    })),
  });

  const group = getGroup(focusedGroup);

  // Change this number to add padding around the map edges
  const fitBounds = padBounds(
    0.003,
    getBoundOfLocations(group.locations.map(l => l.coords)),
  );

  const focusedTarget = focusedLoc !== null && {
    zoom: [FOCUS_ZOOM],
    center: group.locations[focusedLoc].coords,
  };

  const focusedPlace = group?.locations[focusedLoc] ?? null;

  const focusProps = focusedTarget || { fitBounds };

  const emojiMarkers = group.locations.map((loc, i) => (
    <EmojiMarker
      key={`${i}-${loc.name}`}
      anchor="center"
      coordinates={loc.coords}
      onClick={() => setFocusedLoc(i)}
    >
      {loc.emoji}
    </EmojiMarker>
  ));

  const renderCard = (index, modIndex) => (
    <MapLocationList
      whileHover="hovered"
      isFocused={modIndex === focusedGroup}
      key={index}
      variants={locationListAnimations}
      animate={focusedPlace !== null ? 'hidden' : 'visible'}
      onSelect={i => {
        setFocusedGroup(modIndex);
        setFocusedLoc(i);
      }}
      onClick={_ => !isMobile && setFocusedGroup(modIndex)}
      group={getGroup(modIndex)}
    />
  );

  const locationLists = isMobile ? (
    <TouchCarousel
      component={LocationListContainer}
      loop={false}
      cardCount={mapEntries.length}
      onRest={index => {
        setFocusedGroup(index);
        setFocusedLoc(null);
      }}
      renderCard={renderCard}
    />
  ) : (
    <DesktopLocationLists>
      {mapEntries.map((_, i) => renderCard(i, i))}
    </DesktopLocationLists>
  );

  return (
    <Container>
      <PageHeading>{title}</PageHeading>
      <Intro>{introBody}</Intro>

      <StyledMap
        style={mapStyle || DEFAULT_MAP_STYLE}
        movingMethod="easeTo"
        {...focusProps}
      >
        {emojiMarkers}
      </StyledMap>

      {locationLists}
      <PlaceCard
        group={group}
        place={focusedPlace}
        onClose={() => setFocusedLoc(null)}
      />
    </Container>
  );
};

const NonPassiveTouchTarget = ({ onTouchMove, ...props }) => {
  const ref = React.useRef();
  React.useEffect(() => {
    const element = ref.current;
    element.addEventListener('touchmove', onTouchMove, { passive: false });
    return () =>
      element.removeEventListener('touchmove', onTouchMove, { passive: false });
  });

  return <div ref={ref} {...props} />;
};

const LocationListContainer = ({ cursor, carouselState, ...props }) => {
  return (
    <CarouselContainer>
      <CarouselTrack
        style={{ transform: `translateX(calc((100vw - 3rem) * ${cursor}))` }}
        {...props}
      />
    </CarouselContainer>
  );
};

const CarouselContainer = styled('div')`
  position: relative;
  margin: 0 -2rem;
  padding: 2rem;
  overflow: hidden;
  margin-top: -10rem;
  width: 100vw;
  z-index: 3;
`;

const CarouselTrack = styled(NonPassiveTouchTarget)`
  display: flex;
`;

const MapLocationList = styled(LocationList)`
  cursor: pointer;

  @media only screen and (max-device-width: 480px) {
    min-width: calc(100vw - 4rem);
    margin-right: 1rem;
  }
`;

// Adjust how the cards appear on desktop
const DesktopLocationLists = styled('div')`
  position: relative;
  margin-top: -10rem;
  margin-bottom: 2rem;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, 280px);
  grid-gap: 1rem;
  justify-content: center;
  z-index: 3;
`;

const Intro = styled(MDXRenderer)`
  margin-bottom: 2rem;
  text-align: center;
`;

const StyledMap = styled(Map)`
  width: 100%;
  height: 50vh;
  border-radius: 2px;

  @media only screen and (max-device-width: 480px) {
    border-radius: 0;
    margin: 0 -2rem;
    width: calc(100% + 4rem);
  }

  &:after {
    content: '';
    width: 100%;
    height: 100px;
    background: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
    position: absolute;
    bottom: -1px;
  }
`;

const EmojiMarker = styled(MapMarker)`
  font-size: 1.1rem;
  cursor: pointer;
  z-index: 2;
`;

export default Template;

export const pageQuery = graphql`
  query ArtMapEntry($id: String) {
    mdx(id: { eq: $id }) {
      body
      frontmatter {
        slug
        date
        title
        mapStyle
        mapEntries {
          name
          locations {
            place {
              childMdx {
                body
                frontmatter {
                  address
                  coords
                  emoji
                  link
                  name
                  nameJp
                }
              }
            }
          }
        }
      }
    }
  }
`;
