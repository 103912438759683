import React from 'react';
import styled from '@emotion/styled';

const OpenHours = ({ slots }) => (
  <React.Fragment>
    <Title>Opening Hours</Title>
    <HourList>
      {slots.map(([day, time]) => (
        <React.Fragment>
          <dt>{day}</dt>
          <dd>{time}</dd>
        </React.Fragment>
      ))}
    </HourList>
  </React.Fragment>
);

const HourList = styled('dl')`
  color: #666;
  display: grid;
  grid-auto-flow: row;
  grid-auto-rows: max-content max-content;
  grid-template-columns: repeat(2, max-content);
  grid-gap: 0.4rem 1rem;
  font-size: 0.85rem;
  line-height: 1;
  margin-top: 0.5rem;
  margin-bottom: 1rem;

  dt {
    grid-column-start: 1;
  }

  dt,
  dd {
    margin: 0;
  }
`;

const Title = styled('h4')`
  text-transform: uppercase;
  font-size: 1rem;
  font-weight: normal;
  color: #666;
  margin: 0;
`;

export default OpenHours;
