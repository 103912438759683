import styled from '@emotion/styled';

export default styled('h1')`
  font-size: 1.5rem;
  font-weight: normal;
  text-align: center;
  text-transform: uppercase;
  margin: 0 auto;
  margin-bottom: 4rem;
  max-width: 20rem;
  line-height: 1.25;

  &:after {
    content: '';
    display: block;
    border-top: 2px solid #000;
    width: 4rem;
    margin: 0 auto;
    margin-top: 0.5rem;
  }

  @media only screen and (max-device-width: 480px) {
    font-size: 1.25rem;
    margin-bottom: 2rem;
  }
`;
