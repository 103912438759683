import React from 'react';
import styled from '@emotion/styled';
import { motion } from 'framer-motion';

const LocationList = ({ onSelect, group, ...props }) => (
  <Backplate {...props}>
    <Heading>{group.name}</Heading>
    <List>
      {group.locations.map((loc, i) => (
        <LocationItem key={i} onClick={() => onSelect(i)}>
          <Emoji>{loc.emoji}</Emoji>
          <Name>
            {loc.name} / {loc.nameJp}
          </Name>
        </LocationItem>
      ))}
    </List>
  </Backplate>
);

const Backplate = styled(motion.div)`
  position: relative;
  background: #fff;
  padding: 1rem;
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
`;

const Heading = styled('h2')`
  font-size: 1.25rem;
  text-align: center;
  font-weight: normal;
  margin: 0;
  margin-bottom: 1rem;
`;

const List = styled('ul')`
  list-style: none;
  padding: 0;
  margin: 0;
`;

const Name = styled('div')`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const Emoji = styled('div')`
  justify-self: center;
`;

const LocationItem = styled('li')`
  display: grid;
  grid-template-columns: 20px 1fr;
  font-size: 0.8rem;
  cursor: pointer;
  padding: 0.25rem 0;

  &:not(:last-child) {
    border-bottom: 1px solid #dfdfdf;
  }
`;

export default LocationList;
