import React from 'react';
import styled from '@emotion/styled';
import { AnimatePresence, motion } from 'framer-motion';
import { MDXProvider } from '@mdx-js/react';
import { MDXRenderer } from 'gatsby-plugin-mdx';

import OpenHours from 'src/components/OpenHours';

const spring = {
  type: 'spring',
  stiffness: 200,
  damping: 23,
  restSpeed: 1,
};

const containerMotion = {
  initial: { y: '100vh', opacity: 0 },
  exit: {
    y: '40vh',
    opacity: 0,
    transition: { when: 'afterChildren', ...spring },
  },
  enter: {
    y: '0vh',
    opacity: 1,
    transition: {
      when: 'beforeChildren',
      delay: 1,
      ...spring,
    },
  },
};

const backButtonMotion = {
  initial: { x: '-50%', opacity: 0 },
  enter: { x: 0, opacity: 1 },
  exit: { x: '-50%', opacity: 0 },
};

const renderComponents = {
  OpenHours,
};

const PlaceCard = ({ group, place, onClose }) => {
  const infoCard = place !== null && (
    <CardBackplate>
      <HeadingLink href={place.link} target="_blank" rel="noreferrer noopener">
        {place.emoji} {place.name}
      </HeadingLink>
      <HeadingJpName>{place.nameJp}</HeadingJpName>
      <MDXProvider components={renderComponents}>
        <Content>{place.body}</Content>
      </MDXProvider>
    </CardBackplate>
  );

  const container = place !== null && (
    <CardContainer
      initial="initial"
      animate="enter"
      exit="exit"
      variants={containerMotion}
    >
      <ButtonContainer>
        <BackButton onClick={onClose} variants={backButtonMotion}>
          {group.name}
        </BackButton>
      </ButtonContainer>
      {infoCard}
    </CardContainer>
  );

  return <AnimatePresence>{container}</AnimatePresence>;
};

const CardContainer = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: scroll;
  padding-top: 40vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 4;
`;

const ButtonContainer = styled('div')`
  width: 100%;
  max-width: 932px;
`;

const CardBackplate = styled('div')`
  max-width: 932px;
  background: #fff;
  box-shadow: 0px 0 12px rgba(0, 0, 0, 0.2);
  border-radius: 10px 10px 0 0;
  margin-top: 1rem;
  padding: 1.5rem;
  flex-grow: 1;
`;

const BackButton = styled(motion.button)`
  background: #fff;
  font-family: 'Mrs Eaves OT';
  font-size: 1rem;
  letter-spacing: 0.1rem;
  text-transform: uppercase;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  padding: 1rem 2rem;
  display: block;
  border: none;
  cursor: pointer;

  @media only screen and (max-device-width: 480px) {
    border-radius: 0 10px 10px 0;
  }

  :focus {
    outline: none;
  }

  ::before {
    content: '‹';
    font-size: 1.4rem;
    margin-right: 0.5rem;
  }
`;

const HeadingLink = styled('a')`
  color: #000;
  font-size: 1.2rem;
`;

const HeadingJpName = styled('h2')`
  font-size: 1rem;
  font-weight: normal;
  margin: 0;
  margin-top: 0.75rem;
`;
const Content = styled(MDXRenderer)``;

export default PlaceCard;
